import VideoTitle from "./VideoTitle";
import AuthorBox from "./AuthorBox";
import VideoDescription from "./VideoDescription";
import CommentBox from "./CommentBox";

const SingleVideoLeft = (props) => {
	return (
		<>
			<div className="single-video-left box">
				<VideoTitle
					title={props.title}
					body={props.body}
				/>
			</div>
		</>
	);
};

export default SingleVideoLeft;
