import { Link } from "react-router-dom";


import "./Navigation.css";

import Navbar from "react-bootstrap/Navbar";

const Navigation = ({ props }) => {
	return (
		<>
			<Navbar bg="white" sticky="top" className="osahan-nav">
				&nbsp;&nbsp;
				&nbsp;&nbsp;
				<Navbar.Brand className="mr-1">
					<Link to="/adsanddata/after-boost">
						<img className="img-fluid navigation-logo" src="/img/logo.png" alt="" />
					</Link>
				</Navbar.Brand>
				<ul className="navbar-nav ml-md-0 osahan-right-navbar">
					<li className="nav-item mx-1">
						<Link to="/adsanddata/after-boost" className="nav-link">
							<span className=" d-md-inline">
								After Boost
							</span>
						</Link>
					</li>
					<li className="nav-item mx-1">
						<Link to="/adsanddata/binnenkomers" className="nav-link">
							<span className=" d-md-inline">
							Binnenkomers
							</span>
						</Link>
					</li>
					<li className="nav-item mx-1">
						<a className="nav-link" href="https://www.adsanddata.be/team-limburg/" target="_blank" rel="noopener noreferrer">  	
							<span className=" d-md-inline">
								Team Limburg
							</span>
						</a>
					</li>
				</ul>
			</Navbar>
		</>
	);
};

export default Navigation;
