import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";

import Navigation from "./components/Navigation/Navigation";
import Homepage from "./components/Homepage/Homepage";
import VideoPage from "./components/VideoPage/VideoPage";
import FourZeroFour from "./components/FourZeroFour/FourZeroFour";

import { CustomScrollToTop } from "./components/Atomics/ScrollToTop/ScrollToTop";
import SecondPage from "./components/Homepage/SecondPage";

function App() {
  return (
    <Router>
      <Switch>
        <Route path="/">
          <Content />
        </Route>
        <Route path="*" component={FourZeroFour} />
      </Switch>
    </Router>
  );
}

function Content(props) {
  return (
    <>
      <div className="homepage-wrapper">
        <div id="wrapper">
          <Switch>
            <Route exact path="/adsanddata/after-boost">
              <Homepage brand="adsanddata"/>
            </Route>
            <Route exact path="/adsanddata/binnenkomers">
              <Navigation props={props} />
              <SecondPage brand="adsanddata"/>
            </Route>
            <Route exact path="/homie/videos">
              <Homepage brand="homie"/>
            </Route>
            <Route
                exact
               path="/:brand/video/:videoid"
              render={(props) => <VideoPage {...props} />}
            ></Route>
            <Route path="*" component={FourZeroFour} />
          </Switch>
        </div>
        <ScrollToTop
          smooth
          component={<CustomScrollToTop />}
          className="scroll-to-top outline-0"
          color="white"
        />
      </div>
    </>
  );
}

export default App;
