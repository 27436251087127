import "./Homepage.css";
import Container from "react-bootstrap/Container";
import React, { Component } from "react";

import VideoBlock from "./VideoBlock";
import SingleVideo from "../VideoPage/SingleVideo";
import VideoInfoCenter from "../VideoPage/VideoInfoCenter";
import { getVideosByCategory } from "../../webapi/webapi.js";

import ThinFooter from "../Footer/ThinFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import Navigation from "../Navigation/Navigation";
import NavigationHomie from "../Navigation/NavigationHomie";

class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlight: {},
      videos: [],
    };
  }

  componentDidMount() {
    var categoryTop = 'Highlight'
    var category = 'After Boost'
    if (this.props.brand === 'homie') {
      categoryTop = 'Billboard'
      category = 'Reportage'
    }

    getVideosByCategory(this.props.brand, categoryTop).then((res) => {
      if (res.data && Array.isArray(res.data.responses)) {
        if (res.data.responses[0].hits.hits.length > 0) {
          this.setState({ highlight: res.data.responses[0].hits.hits[0]._source});
        }
      }
    });

    getVideosByCategory(this.props.brand, category).then((res) => {
      if (res.data && Array.isArray(res.data.responses)) {
        this.setState({ videos: res.data.responses[0].hits.hits });
      }
    });
  }

  render() {
    const isHomie = this.props.brand === 'homie'
    return (
      <>
        {isHomie ? <NavigationHomie/> :<Navigation/> }
        <ContentWrapper>
          <Container fluid className="bodyContainer">
            <div className="row justify-content-md-center">
              <div className=" col-md-3 col-sm-1"></div>
              <div className=" col-md-6  col-sm-10">
              {this.state.highlight.StreamOneId? (<SingleVideo videoId={this.state.highlight.StreamOneId} />): null} 
              </div>
              <div className="col-md-3 col-sm-1"></div>
            </div>
            <VideoInfoCenter highlight={this.state.highlight}/>
            <hr />
            <VideoBlock videos={this.state.videos} />
          </Container>

          <ThinFooter />
        </ContentWrapper>
      </>
    );
  }
}

export default Homepage;
