import SectionHeader from "../Atomics/SectionHeader/SectionHeader";
import { Link } from "react-router-dom";
import {
  VerifiedTooltip,
  UnverifiedTooltip,
} from "../Atomics/CustomCheckTooltips/CustomCheckTooltips";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlayCircle,
  faEllipsisV,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";

const VideoCardList = ({
  imgAlt = "",
  imgSrc,
  videoTitle,
  videoCategory,
  views,
  timeAgo,
  videoHref = "video",
  time,
  active = null,
  verified = false,
}) => {
  const activeStatus = active
    ? "video-card video-card-list active"
    : "video-card video-card-list";
  const categoryClass = verified
    ? "video-page text-success-custom"
    : "video-page text-danger-custom";
  return (
    <>
      <Link to={videoHref}>
        <div className={activeStatus}>
          <div className="video-card-image">
            <div className="play-icon" >
              <FontAwesomeIcon icon={faPlayCircle} />
            </div>
            <img className="img-fluid" src={imgSrc.replace(".png", "_small.png")} alt={imgAlt} />
            <div className="time">{time}</div>
          </div>

          <div className="video-card-body">
            <SectionHeader dropdownOnly icon={faEllipsisV} noIconLabel />

            <div className="video-title">
              {videoTitle}
            </div>
          </div>
        </div>
      </Link>
    </>
  );
};

export default VideoCardList;
