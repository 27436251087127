import "./Homepage.css";
import Container from "react-bootstrap/Container";
import React, { Component } from "react";

import VideoBlock from "./VideoBlock";
import { getVideosByCategory } from "../../webapi/webapi.js";

import ThinFooter from "../Footer/ThinFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

class SecondPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videos: [],
    };
  }

  componentDidMount() {
    getVideosByCategory(this.props.brand, 'Lokaal Adverteren').then((res) => {
      if (res.data && Array.isArray(res.data.responses)) {
        this.setState({ videos: res.data.responses[0].hits.hits });
      }
    });
  }

  render() {
    return (
      <>
        <ContentWrapper>
          <Container fluid className="bodyContainer">
            <VideoBlock videos={this.state.videos} />
          </Container>
          <ThinFooter />
        </ContentWrapper>
      </>
    );
  }
}

export default SecondPage;
