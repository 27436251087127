import React, { Component } from "react";
import "./VideoPage.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SingleVideo from "./SingleVideo";
import { Redirect } from "react-router-dom";

import ThinFooter from "../Footer/ThinFooter";
import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

import SingleVideoRight from "./SingleVideoRight";
import SingleVideoLeft from "./SingleVideoLeft";
import { getVideosByCategory, getVideoById } from "../../webapi/webapi.js";

import Navigation from "../Navigation/Navigation";
import NavigationHomie from "../Navigation/NavigationHomie";

class VideoPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videos: [],
			assetId: "",
			videoId: "",
			title: "",
			category: "",
			body: "",
			redirect: false,
		};
	}

	componentDidMount() {
		this.setState({
			assetId: this.props.match.params.videoid,
		});

		this.loaddata();
	}

	componentDidUpdate(prevProps) {
		if (this.props !== prevProps) {
			this.loaddata();
			window.scrollTo(0, 0);
		}
	}

	loaddata() {
		getVideoById(
			this.props.match.params.brand,
			this.props.match.params.videoid
		).then((res) => {
			if (res.data && Array.isArray(res.data.responses)) {
				if (res.data.responses[0].hits.hits[0]) {
					this.setState({
						videoId: res.data.responses[0].hits.hits[0]._source.StreamOneId,
						title: res.data.responses[0].hits.hits[0]._source.Title,
						category: res.data.responses[0].hits.hits[0]._source.Category,
						body: res.data.responses[0].hits.hits[0]._source.Description,
					});

					getVideosByCategory(
						this.props.match.params.brand,
						res.data.responses[0].hits.hits[0]._source.Category
					).then((res) => {
						if (res.data && Array.isArray(res.data.responses)) {
							this.setState({ videos: res.data.responses[0].hits.hits });
						}
					});
				} else {
					this.setState({ redirect: true });
				}
			}
		});
	}

	render() {
		const isHomie = this.props.match.params.brand === "homie";

		return (
			<>
				{isHomie ? <NavigationHomie /> : <Navigation />}
				{this.state.redirect ? <Redirect to={isHomie ? "/homie/videos" : "/adsanddata/after-boost"}/> : null}
				<ContentWrapper>
					<Container fluid>
						<div className="video-block-right-list section-padding">
							<Row className="mb-4">
								<Col md={8}>
									{this.state.videoId ? (
										<SingleVideo videoId={this.state.videoId} />
									) : null}
									<SingleVideoLeft
										title={this.state.title}
										body={this.state.body}
									/>
								</Col>
								<Col md={4}>
									<div>
										<img className="adsLogo" src={isHomie ? "/img/homie_logo.png" : "/img/adsdata_logo.png"}></img>
									</div>
									<SingleVideoRight videos={this.state.videos} brand={this.props.match.params.brand}/>
								</Col>
							</Row>
						</div>

						<div className="video-block section-padding">
							<Row>
								<Col md={8}></Col>
								<Col md={4}></Col>
							</Row>
						</div>
					</Container>
					<ThinFooter />
				</ContentWrapper>
			</>
		);
	}
}

export default VideoPage;
