import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export default function VideoTitle({ title, body }) {
	return (
		<>
			<div className="single-video-title mb-3">
				<h2>
					<a>{title}</a>
				</h2>
				<p>{body}</p>
			</div>
		</>
	);
}


