import "./SectionHeader.css";
import Dropdown from "react-bootstrap/Dropdown";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faStar,
	faSignal,
	faTimesCircle,
	faCaretDown,
} from "@fortawesome/free-solid-svg-icons";

const SectionHeader = ({
	heading,
	icon = null,
	noIconLabel = false,
	dropdownOnly = null,
}) => {
	let dropdownIcon = icon ? icon : faCaretDown;
	let dropdownIconLabel = noIconLabel ? "" : "Sort by";

	let dropdownBodyz;

	if (dropdownOnly) {
		dropdownBodyz = (
			<ButtonGroup
				dropdownIcon={dropdownIcon}
				dropdownIconLabel={dropdownIconLabel}
			/>
		);
	} else {
		dropdownBodyz = (
			<HeadingWrapper heading={heading}>
			</HeadingWrapper>
		);
	}

	return <>{dropdownBodyz}</>;
};

function ButtonGroup({ dropdownIcon, dropdownIconLabel }) {
	return (
		<>

		</>
	);
}

function HeadingWrapper({ children, heading }) {
	return (
		<>
			<div className="main-title">
				{children ? children : ""}
				<h6>{heading}</h6>
			</div>
		</>
	);
}

export default SectionHeader;
export { HeadingWrapper };
