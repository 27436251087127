import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import VideoCard from "../Atomics/VideoCard/VideoCard";

export default function VideoBlock(props) {
	return (
		<>
			<div className="video-block section-padding ">
				<Row id="VideoBlock">
					<Col md={12}>
						<Row>
						<div className="text-powerby">
									Videos powered by{" "}
									<a
										className="text-primary-custom"
										target="_blank"
										href="https://www.deburen.tv/"
										rel="noreferer"
									>
										De Buren
									</a>
								</div>
						</Row>
					</Col>
					{props.videos.map((video, index) => (
						<Col
							xl={3}
							sm={6}
							className="mb-3"
							key={video._source.StreamOneId}
						>
							<VideoCard
								imgSrc={
									"https://api-zebra.mediahuis.be/" +
									video._source.Video.Proxy.Thumbnail
								}
								videoTitle={video._source.Title}
								verified
								iconHref={"video/" + video._source.AssetID }
								imgHref={"video/" + video._source.AssetID }
								videoTitleHref={
									"video/" + video._source.AssetID 
								}
							/>
						</Col>
					))}
				</Row>
			</div>
		</>
	);
}
