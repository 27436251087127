import "./VideoPage.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ContentWrapper from "../Atomics/ContentWrapper/ContentWrapper";

export default function SingleVideo(props) {
  const vidSrc =
    "https://player.agnoplay.com/embed/tvl?videoId=" +
    props.videoId +
    "&lk0=a6ec&lk1=07d8bb07&lk2=3f7af589b27e&lk3=4fe4&lk4=5a2a";

  return (
    <>
      <div className="single-video">
        <iframe
          src={vidSrc}
          className="videoIframe"
          frameBorder="0"
          allow=" encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </>
  );
}
