import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import VideoCardList from "./VideoCardList";
import SectionHeader from "../Atomics/SectionHeader/SectionHeader";

export default function SingleVideoRight(props) {
  const isHomie = props.brand === "homie";
  return (
    <>
      <div className="single-video-right">
        <Row>
          {props.videos.slice(0,8).map((video, index) => (
            <Col md={12} key={video._source.StreamOneId}>
              <VideoCardList
                imgSrc={
                  "https://api-zebra.mediahuis.be/" +
                  video._source.Video.Proxy.Thumbnail
                }
                videoTitle={video._source.Title}
                verified
                videoHref={isHomie ? "/homie/video/" + video._source.AssetID : "/adsanddata/video/" + video._source.AssetID }
              />
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

function AdBlock() {
  return (
    <>
      <div className="adblock">
        <div className="img">
          Google AdSense
          <br />
          336 x 280
        </div>
      </div>
    </>
  );
}

export { AdBlock };
