import { Link } from "react-router-dom";


import "./Navigation.css";

import Navbar from "react-bootstrap/Navbar";

const NavigationHomie = ({ props }) => {
	return (
		<>
			<Navbar bg="white" sticky="top" className="osahan-nav">
				&nbsp;&nbsp;
				&nbsp;&nbsp;
				<Navbar.Brand className="mr-1">
					<Link to="/homie/videos">
						<img className="img-fluid navigation-logo" src="/img/homie.png" alt="" />
					</Link>
				</Navbar.Brand>
			</Navbar>
		</>
	);
};

export default NavigationHomie;
