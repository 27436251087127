import "./VideoCard.css";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle} from "@fortawesome/free-solid-svg-icons";

export default function VideoCard({
	iconHref = "video",
	imgHref = "video",
	imgSrc,
	imgAlt = "",
	time = "-:-",
	videoTitle = "#",
	videoTitleHref = 'video',
	
}) {
	return (
		<>
		<Link to={videoTitleHref}>
			<div className="video-card">
				<div className="video-card-image">
					<span className="play-icon">
						<FontAwesomeIcon icon={faPlayCircle} className="play-icon"/>
					</span>

						<img className="img-fluid" src={imgSrc.replace(".png", "_small.png")} alt={imgAlt} />

					
				</div>
				<div className="video-card-body">
					<div className="video-title">
						{videoTitle}
					</div>
				</div>
			</div>
			</Link>
		</>
	);
}

