import "./FatFooter.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";

const ThinFooter = () => {
	return (
		<>
			<footer className="sticky-footer">
				<Container>
					<Row className="no-gutters">
						<Col lg={6} sm={6}>
							<p className="mt-1 mb-0">
								<small className="mt-0 mb-0">
									
							
									Powered by{" "}
									<a
										className="text-primary-custom"
										target="_blank"
										href="https://www.deburen.tv/"
										rel="noreferer"
									>
										<img className="img-fluid navigation-logo" src="/img/deburen.png" alt="" />
									</a>
								</small>
							</p>
						</Col>
						<Col lg={6} sm={6} className="text-right">
						</Col>
					</Row>
				</Container>
			</footer>
		</>
	);
};

export default ThinFooter;
