import "es6-promise/auto";
import axios from "axios";
var FormData = require('form-data');

export function getVideosByCategory(brand, category) {
  var data = new FormData();
  data.append('brand', brand);
  data.append('category', category);
  return axios({
    method: "post",
    url: "https://deburen.azurewebsites.net/tma/category.php",
    data: data,
    headers: { "Content-Type": "application/x-ndjson" },
  })
}

export function getVideoById(brand, id) {
  var data = new FormData();
  data.append('brand', brand);
  data.append('id', id);
  return axios({
    method: "post",
    url: "https://deburen.azurewebsites.net/tma/video.php",
    data: data,
    headers: { "Content-Type": "application/x-ndjson" },
  })
}