import VideoTitle from "./VideoTitle";
import "./VideoPage.css";

export default function VideoInfoCenter(props) {
  return (
    <>
      <div className="row justify-content-md-center">
        <div className=" col-md-3 col-sm-1"></div>
        <div className=" col-md-6 col-sm-10 single-video-center ">
          <VideoTitle
            title={props.highlight.Title}
            body={props.highlight.Description}
          />
        </div>
        <div className=" col-md-3 col-sm-1"></div>
      </div>
    </>
  );
}
